import apiClient from './axios/index'

const createMangoolsKeywork = (param) =>
    apiClient
        .post('/mangools-keywork', param)
        .then((response) => response.data)
        .catch((err) => console.log(err))

const updateMangoolsKeywork = (param) =>
    apiClient
        .put('/mangools-keywork', param)
        .then((response) => response.data)
        .catch((err) => console.log(err))
const deleteMangoolsKeywork = (param) =>
    apiClient
        .delete('/mangools-keywork', { params: param })
        .then((response) => {
            return response.data
        })
        .catch((err) => console.log(err))

const getMangoolsKeywork = (param) =>
    apiClient
        .get('/mangools-keywork', { params: param })
        .then((response) => response.data)
        .catch((err) => console.log(err))

const createSearchEngineSimulator = (param) =>
    apiClient
        .post('/search-engine-simulator', param)
        .then((response) => response.data)
        .catch((err) => console.log(err))

const createPagespeedInsights = (param) =>
    apiClient
        .post('/pagespeed-insights', param)
        .then((response) => response.data)
        .catch((err) => console.log(err))

const login = (param) =>
    apiClient
        .post(`/login`, param)
        .then((response) => response)

const getCreditsApi = () =>
    apiClient
        .post(`/remaining-credits`)
        .then((response) => response.data)
// .catch((err) =>console.log(err.response.data))

const mangoolsKeyworkService = (param) =>
    apiClient
        .get(`/mangools-keywork-service`, { params: param })
        .then((response) => response.data)
const getKeywordByUrl = (param) =>
    apiClient
        .get(`/get-keyword-by-url`, { params: param })
        .then((response) => response.data)
const getAllKeyword = () =>
    apiClient
        .get(`/get-all-keyword`)
        .then((response) => response.data)
const getKeywordDensity = (param) =>
    apiClient
        .get(`/keyword-density`, { params: param })
        .then((response) => response.data)
const getAverageToptenWebsite = (param) =>
    apiClient
        .get(`/average-topten-website`, { params: param })
        .then((response) => response.data)
const getTitleAndDesc = (param) =>
    apiClient
        .get(`/get-title-and-description`, { params: param })
        .then((response) => response.data)

export default {
    createMangoolsKeywork,
    createSearchEngineSimulator,
    createPagespeedInsights,
    login,
    getMangoolsKeywork,
    updateMangoolsKeywork,
    getCreditsApi,
    mangoolsKeyworkService,
    getAllKeyword,
    getKeywordByUrl,
    getKeywordDensity,
    getAverageToptenWebsite,
    getTitleAndDesc,
    deleteMangoolsKeywork
}
