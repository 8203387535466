import _ from "lodash";
import {
    Button,
    FormControl,
    FormHelperText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import Moment from 'moment';
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";


import React, { useEffect, useState } from "react";
import { useKeywords } from "../../hooks/useKeywords";
import ResultsTableSection from "./ResultsTableSection";
import { Controller, useForm } from "react-hook-form";
import SummaryCards from "./SummaryCards";
import { TableExport } from "tableexport";
import API from "../../services/API";

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        display: "flex",
        marginTop: theme.spacing(4),
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

const ResultsTableDB = () => {
    const classes = useStyles();
    const { control, handleSubmit, setValue } = useForm();
    const [keywordsDataDB, setKeywordsDataDB] = useState();
    /**
     * Sort rows by attribute
     */
    const [dataTable, setDataTable] = useState();
    useEffect(() => {
        const fetchKeywords = async () => {
            const resApiData = await API.getAllKeyword();
            let arrUrl = [];
            let resApiDataReturn = [];
            resApiData && resApiData.forEach(function (item, index) {
                if(arrUrl.indexOf(item.url) == -1 ) {
                    arrUrl.push(item.url);
                    resApiDataReturn.push(item);
                }
            })
            setKeywordsDataDB(resApiDataReturn);

        }
        fetchKeywords();
    }, []);

    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
 
    const Sortable = ({ property, label, tooltip = null }) =>
        tooltip ? (
            <Tooltip title={tooltip}>
                <TableSortLabel
                    active={orderBy === property}
                    onClick={() => handleRequestSort(property)}
                    direction={orderBy === property ? order : "asc"}
                >
                    {label}
                </TableSortLabel>
            </Tooltip>
        ) : (
            <TableSortLabel
                active={orderBy === property}
                onClick={() => handleRequestSort(property)}
                direction={orderBy === property ? order : "asc"}
            >
                {label}
            </TableSortLabel>
        );

        if(keywordsDataDB ){
            return (
                <>
                    {<Table size="small" id="main-table" aria-label="keywords results table" style={{ marginBottom: 40, marginTop: 40 }} >
                        <TableHead>
                            <TableRow>
                                <TableCell > <Sortable property="No" label="No." /> </TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>Date Update</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keywordsDataDB && keywordsDataDB.map((row, index) => {
                                return (<TableRow key={index+1}>
                                    <TableCell>{index + 1 || "-"}</TableCell>
                        
                                    <TableCell>
                                        <Link to={`view-keyword-url?url=${row.url}`} className={classes.link}>
                                        {row.url || ""}
                                        </Link>
                                    </TableCell>
                                    <TableCell >
                                        {Moment(row.resultDate).format('DD/MM/YYYY')}
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>}
                </>
            );
        } else {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

   
};

export default ResultsTableDB;
