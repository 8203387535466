import API from "./API";

const base_uri = process.env.REACT_APP_CUSTOMAPI;

export const getCredits = async () => {
    
    return API.getCreditsApi()
    .then((res)=>{
        if (res) {
            return {
                credits: {
                    kw: {
                        remaining:
                            res.credits.resources["related-keywords"].remaining,
                        total: res.credits.resources["related-keywords"].limit,
                    },
                    serp: {
                        remaining: res.credits.resources.serps.remaining,
                        total: res.credits.resources.serps.limit,
                    },
                    sp: {
                        remaining: res.credits.resources["sp-overview"].remaining,
                        total: res.credits.resources["sp-overview"].limit,
                    },
                    links: {
                        remaining: res.credits.resources.links.remaining,
                        total: res.credits.resources.links.limit,
                    },
                    ttr: `${Math.floor(
                        res.credits.resources.serps.reset / 3600
                    )}h ${Math.floor(
                        (res.credits.resources.serps.reset % 3600) / 60
                    )}m to reset`,
                },
            };
        } 
    })
    .catch((err)=>{
        console.log(err);
    })
};

export const searchEngineSimulator = async (url) => {
    const encodedUri = encodeURIComponent(url);
    const req_url = `${base_uri}/search-engine-simulator?url=${encodedUri}`;
    const reqOption = {
      headers: {
        "token": sessionStorage.getItem("token"),
        "logintype" :sessionStorage.getItem("login_type") ? sessionStorage.getItem("login_type") : "login",
      }
    }
    try {
      const response = await fetch(req_url,reqOption );
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };