import { useKeywords } from "../hooks/useKeywords";
import KeywordPageOneDisplay from "../components/KeywordTimelineAnalysis/KeywordPageOneDisplay";
import RelatedKeywordTable from "../components/KeywordTimelineAnalysis/RelatedKeywordTable";
import KeywordDomainDisplay from "../components/KeywordTimelineAnalysis/KeywordDomainDisplay";

import API from "../services/API";
import {
    Button,
    makeStyles,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Table,
    IconButton,
    FormControl,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import { RefreshOutlined } from "@material-ui/icons";
import { Launch } from "@material-ui/icons";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import PageSpeedInsights from "../components/KeywordTimelineAnalysis/PageSpeedInsights";
import SearchEngineSimulator from "../components/KeywordTimelineAnalysis/SearchEngineSimulator";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Redirect, useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "moment";
import React, { useState, useEffect } from "react";

import TableNote from "../components/TableNote";
import { getKeywordData } from "../services/mangools";
import countries from "../catalogs/countries";
import { Controller, useForm } from "react-hook-form";
import { render } from "@testing-library/react";

const sorter = require("array-sorter");

const useStyles = makeStyles((theme) => ({
    topBar: {
        display: "flex",
        marginTop: "2rem",
        marginBottom: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
    },
    bottomBar: {
        display: "flex",
        marginTop: theme.spacing(4),
        alignItems: "center",
        justifyContent: "space-between",
    },
    creditBalance: {
        fontWeight: "bold",
    },
    statusContainer: { display: "flex", alignItems: "center" },
    statusText: { marginLeft: "8px", fontSize: "14px" },
    accordion: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    tableKW: {
        marginBottom: 40,
        marginTop: 40,
        display: "block",
        overflowX: "auto",
        overflowY: "auto",
    },
    fixedNo: {
        position: "-webkit-sticky",
        position: "sticky",
        background: "whitesmoke",
        left: 0,
        zIndex: 1,
        width: "50px",
    },
    fixedKw: {
        position: "-webkit-sticky",
        position: "sticky",
        background: "whitesmoke",
        left: "50px",
        zIndex: 1,
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    flex: {
        display: "flex",
    },
}));

const ViewKeywordUrl = () => {
    const classes = useStyles();
    const {
        url,
        keyword,
        locationData,
        setActiveKeyword,
        CmSetActiveKeyword,
        setUrl,
        setLocationData,
        keywords,
        status,
        setStatus,
    } = useKeywords();

    const [keywordsDataDB, setKeywordsDataDB] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [contentPopup, setContentPopup] = useState({
        title: <CircularProgress />,
        description: <CircularProgress />,
    });
    const [fileName, setFileName] = useState("main-table");
    const history = useHistory();
    const { control, handleSubmit, setValue } = useForm();

    const fetchKeywordData = async (kw) => {
        const paramGet = {
            url: url,
            locationId:
                JSON.stringify(locationData && locationData.mangools) || "2702",
            keyWords: kw,
        };
        const resApiData = await API.getMangoolsKeywork(paramGet);
        const lastTwoWeek = new Date() - 12096e5;
        const dateUpdateData = new Date(
            resApiData && resApiData[0] ? resApiData[0].resultDate : "null"
        ).getTime();
        if (
            resApiData &&
            resApiData[0] &&
            dateUpdateData > lastTwoWeek &&
            resApiData[0].keyWordsResult != "undefined"
        ) {
            return JSON.parse(resApiData[0].keyWordsResult);
        } else if (resApiData && resApiData[0]) {
            const keyword = kw;
            const location = locationData.mangools;
            return await getKeywordData(url, keyword, location)
                .then((kwdata) => {
                    const param = new URLSearchParams({
                        _id: resApiData[0]._id,
                        keyWordsResult: JSON.stringify(kwdata),
                    });
                    API.updateMangoolsKeywork(param)
                        .then((response) => {})
                        .catch((e) => {
                            console.log(e);
                        });
                    return kwdata;
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            return await getKeywordData(url, kw, locationData.mangools)
                .then((kwdata) => {
                    let data = kwdata;
                    if (kwdata) {
                        const param = new URLSearchParams({
                            url: url,
                            locationId: JSON.stringify(locationData.mangools),
                            keyWords: kw,
                            keyWordsResult: JSON.stringify(data),
                        });
                        API.createMangoolsKeywork(param)
                            .then((response) => {})
                            .catch((e) => {
                                console.log(e);
                            });
                        return kwdata;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const fetchKeywords = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const url = params.get("url");

        const paramsUrl1 = params.get("url").slice(0, -1);
        let domain = new URL(paramsUrl1);
        const paramsUrl = domain.protocol + "//" + domain.hostname;
        setUrl(url);
        setActiveKeyword(null);
        const arrDomain = domain.hostname.replace("www.", "").split(".");
        setFileName(arrDomain[0]);
        const resApiDataTemp = await API.getKeywordByUrl({ url });
        let resApiDataReturn = [];

        const resApiData = resApiDataTemp
            .filter((e) => !e.keyWords.includes("http://"))
            .filter((e) => !e.keyWords.includes("https://"));

        if (resApiData.length == 0) {
            history.push("/app/keyword-timeline-analysis");
        } else {
            await Promise.all(
                resApiData.map(async (item) => {
                    if (item.keyWordsResult != "undefined") {
                        const keyWordsResult = JSON.parse(item.keyWordsResult);
                        let urlWordCount = paramsUrl + keyWordsResult?.path;
                        if (keyWordsResult && keyWordsResult.path == "-") {
                            urlWordCount = paramsUrl;
                        }
                        const param = {
                            url: urlWordCount,
                            keyword: item?.keyWords,
                        };
                        await API.getKeywordDensity(param).then((response) => {
                            item["keywordDensity"] = response;
                            item["countKeyWord"] = response?.countKeyWord;
                        });
                        let kwdata = null;
                        if (item.keyWordsResult) {
                            try {
                                kwdata = JSON.parse(item.keyWordsResult);
                            } catch (e) {
                                return;
                            }
                        }
                        let stringUrl = "";
                        const topTenUrl = JSON.parse(
                            item.keyWordsResult
                        ).items.slice(0, 9);
                        topTenUrl.forEach((element, index) => {
                            if (index + 1 < topTenUrl.length) {
                                stringUrl += element.url + ",";
                            } else if (index + 1 == 10) {
                                stringUrl += element.url;
                                return;
                            }
                        });
                        await API.getAverageToptenWebsite({
                            url: stringUrl,
                            keyword: item?.keyWords,
                        })
                            .then((response) => {
                                item["averageToptenWebsite"] = response;
                                item["minDensity"] = response?.minDensity;
                                item["maxDensity"] = response?.maxDensity;
                                item["averageDensity"] =
                                    response?.averageDensity;
                                item["averageWordCount"] =
                                    response?.averageWordCount;
                                item["minWordCount"] = response?.minWordCount;
                                item["maxWordCount"] = response?.maxWordCount;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        item["rank"] = parseInt(kwdata?.rank);
                        item["search"] = kwdata?.search;
                        item["ev"] = kwdata?.ev;
                        item["cpc"] = kwdata?.cpc;
                        item["ppc"] = kwdata?.ppc;
                        switch (kwdata?.level) {
                            case "low":
                                item["levelSort"] = 1;
                                break;
                            case "medium":
                                item["levelSort"] = 2;
                                break;
                            case "high":
                                item["levelSort"] = 3;
                                break;
                            case "others":
                                item["levelSort"] = 4;
                            default:
                                break;
                        }
                        resApiDataReturn.push(item);
                    }
                })
            );
            const priorities = {
                low: 0,
                medium: 1,
                high: 2,
                others: 3,
            };
            resApiDataReturn.sort(function (a, b) {
                return (
                    priorities[JSON.parse(a.keyWordsResult).level] -
                    priorities[JSON.parse(b.keyWordsResult).level]
                );
            });

            setKeywordsDataDB(resApiDataReturn);
            setLocationData({
                mangools: parseInt(
                    resApiDataReturn && resApiDataReturn[0].locationId
                ),
                sem: "sg",
            });
            CmSetActiveKeyword(
                resApiData.url,
                ["Singapore"],
                {
                    mangools: 2702,
                    sem: "sg",
                },
                [resApiData.keywords],
                JSON.parse(resApiData[0].keyWordsResult)
            );
        }
    };

    const [keyArr, setKeyArr] = useState([]);
    const removeKeywordDb = async (data) => {
        setKeyArr([...keyArr, data]);
        const res = await API.deleteMangoolsKeywork({ _id: data })
            .then((response) => {
                NotificationManager.success(
                    "Delete " + response.keyWords + "success",
                    "",
                    3000
                );
                // fetchKeywords();
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const [isLoading, setLoading] = useState(false);
    const [key, setKey] = useState(null);

    const refreshKeywordDb = async (data) => {
        setLoading(true);
        setKey(data._id);
        await getKeywordData(data.url, data.kw, data.locationId)
            .then((kwdata) => {
                const param = new URLSearchParams({
                    _id: data._id,
                    keyWordsResult: JSON.stringify(kwdata),
                });
                API.updateMangoolsKeywork(param)
                    .then((response) => {
                        fetchKeywords();
                        setLoading(false);
                        setKey(null);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        fetchKeywords();
    }, []);

    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const Sortable = ({ property, label, tooltip = null }) =>
        tooltip ? (
            <Tooltip title={tooltip}>
                <TableSortLabel
                    active={orderBy === property}
                    onClick={() => handleRequestSort(property)}
                    direction={orderBy === property ? order : "asc"}
                >
                    {label}
                </TableSortLabel>
            </Tooltip>
        ) : (
            <TableSortLabel
                active={orderBy === property}
                onClick={() => handleRequestSort(property)}
                direction={orderBy === property ? order : "asc"}
            >
                {label}
            </TableSortLabel>
        );

    if (order === "asc") {
        sorter(keywordsDataDB)
            .orderBy((x) => x[orderBy])
            .sort();
    } else {
        sorter(keywordsDataDB)
            .orderByDescending((x) => x[orderBy])
            .sort();
    }

    const [reRender, setReRender] = useState(false);

    const fetchKeywordWithWordCount = async (kw) => {
        setReRender(false);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const paramsUrl1 = params.get("url").slice(0, -1);
        let domain = new URL(paramsUrl1);
        const paramsUrl = domain.protocol + "//" + domain.hostname;
        let keyWordsResultTemp = {};
        const keyWordsResult = await fetchKeywordData(kw);
        keyWordsResultTemp.keyWordsResult = JSON.stringify(keyWordsResult);

        keyWordsResultTemp["rank"] = parseInt(keyWordsResult?.rank);
        keyWordsResultTemp["search"] = keyWordsResult?.search;
        keyWordsResultTemp["ev"] = keyWordsResult?.ev;
        keyWordsResultTemp["cpc"] = keyWordsResult?.cpc;
        keyWordsResultTemp["ppc"] = keyWordsResult?.ppc;
        keyWordsResultTemp["keyWords"] = kw;
        keyWordsResultTemp["url"] = paramsUrl;
        switch (keyWordsResult?.level) {
            case "low":
                keyWordsResultTemp["levelSort"] = 1;
                break;
            case "medium":
                keyWordsResultTemp["levelSort"] = 2;
                break;
            case "high":
                keyWordsResultTemp["levelSort"] = 3;
                break;
            case "others":
                keyWordsResultTemp["levelSort"] = 4;
            default:
                break;
        }

        switch (keyWordsResult?.level) {
            case "low":
                keyWordsResultTemp["levelSort"] = 1;
                break;
            case "medium":
                keyWordsResultTemp["levelSort"] = 2;
                break;
            case "high":
                keyWordsResultTemp["levelSort"] = 3;
                break;
            case "others":
                keyWordsResultTemp["levelSort"] = 4;
            default:
                break;
        }

        let urlWordCount = paramsUrl + keyWordsResult?.path;
        if (keyWordsResult && keyWordsResult.path == "-") {
            urlWordCount = paramsUrl;
        }
        const param = {
            url: urlWordCount,
            keyword: keyWordsResult?.kw,
        };
        await API.getKeywordDensity(param).then((response) => {
            keyWordsResultTemp["keywordDensity"] = response;
            keyWordsResultTemp["countKeyWord"] = response?.countKeyWord;
        });

        let stringUrl = "";
        const topTenUrl = keyWordsResult.items.slice(0, 9);
        topTenUrl.forEach((element, index) => {
            if (index + 1 < topTenUrl.length) {
                stringUrl += element.url + ",";
            } else if (index + 1 == 10) {
                stringUrl += element.url;
                return;
            }
        });
        await API.getAverageToptenWebsite({
            url: stringUrl,
            keyword: keyWordsResult?.kw,
        })
            .then((response) => {
                keyWordsResultTemp["averageToptenWebsite"] = response;
                keyWordsResultTemp["minDensity"] = response?.minDensity;
                keyWordsResultTemp["maxDensity"] = response?.maxDensity;
                keyWordsResultTemp["averageDensity"] = response?.averageDensity;
                keyWordsResultTemp["averageWordCount"] =
                    response?.averageWordCount;
                keyWordsResultTemp["minWordCount"] = response?.minWordCount;
                keyWordsResultTemp["maxWordCount"] = response?.maxWordCount;
            })
            .catch((err) => {
                console.log(err);
            });

        return keyWordsResultTemp;
    };

    const onSubmit = async ({ keyword }) => {
        setValue("keyword", "");
        const myArrKeyword =
            keyword
                .toLowerCase()
                .replace(/\n/g, ",")
                .split(",")
                .filter((kw) => !!kw.trim()) || [];
        const kwTrim = myArrKeyword.map((kw, index) => {
            return kw.trim();
        });

        var listKwAddmore = kwTrim.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });

        let newKeywordsDataDB = keywordsDataDB;
        await Promise.all(
            listKwAddmore.map(async (kw) => {
                setStatus(`Requesting data for ${kw}`);
                const keyWordsResultTemp = await fetchKeywordWithWordCount(kw);
                setStatus(null);
                newKeywordsDataDB.push(keyWordsResultTemp);
            })
        );
        setKeywordsDataDB(newKeywordsDataDB);
        setReRender(true);
    };

    useEffect(() => {}, [reRender, keywordsDataDB]);
    useEffect(() => {
        const getdata = async () => {
            let newKeywordsDataDB = keywordsDataDB;

            if (keywords) {
                const kw = keywords.slice(-1);
                if (kw[0] != undefined) {
                    setStatus(`Requesting data for ${kw[0]}`);
                    const keyWordsResultTemp = await fetchKeywordWithWordCount(
                        kw[0]
                    );
                    newKeywordsDataDB.push(keyWordsResultTemp);
                    setKeywordsDataDB(newKeywordsDataDB);
                    setStatus(null);

                    setReRender(true);
                }
            }
        };
        getdata();
    }, [keywords]);

    if (keywordsDataDB && keywordsDataDB.length !== 0) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const paramsUrl1 = params.get("url").slice(0, -1);
        let domain = new URL(paramsUrl1);
        const paramsUrl = domain.protocol + "//" + domain.hostname;
        const handlePopoverOpen = (event, url) => {
            const param = { url: url };
            API.getTitleAndDesc(param).then((response) => {
                // const content = "Title: " + response.title + "<hr> Description: " + response.description;
                setContentPopup(response);
            });
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
            setContentPopup({
                title: <CircularProgress />,
                description: <CircularProgress />,
            });
        };

        const open = Boolean(anchorEl);

        return (
            <>
                <div>
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <h4>
                            <b>Title: </b> {contentPopup.title}
                        </h4>
                        <p>
                            <b>Description: </b> {contentPopup.description}
                        </p>
                    </Popover>
                </div>
                <div style={{ marginTop: "50px" }}></div>
                <PageSpeedInsights />
                <SearchEngineSimulator />
                <TableNote />
                <div className={classes.topBar}>
                    <div>
                        {!!status && (
                            <div className={classes.statusContainer}>
                                <ClipLoader size={10} color={"#123abc"} />
                                <p className={classes.statusText}>{status}</p>
                            </div>
                        )}
                    </div>
                </div>
                <Table
                    size="small"
                    id="main-table"
                    aria-label="keywords results table"
                    className={classes.tableKW}
                    style={{}}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.fixedNo}>
                                No.
                            </TableCell>
                            <TableCell className={classes.fixedKw}>
                                <Sortable
                                    property="levelSort"
                                    label="Keyword"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable property="rank" label="#" />
                            </TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Meta Descr</TableCell>
                            <TableCell>
                                <Sortable
                                    property="minDensity"
                                    label="KW Min"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    property="maxDensity"
                                    label="KW Max"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    property="averageDensity"
                                    label="KW Avg"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    property="countKeyWord"
                                    label="KW Tgt"
                                />
                            </TableCell>

                            <TableCell>
                                <Sortable
                                    property="minWordCount"
                                    label="Word Min"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    property="maxWordCount"
                                    label="Word Max"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    property="averageWordCount"
                                    label="Word Avg"
                                />
                            </TableCell>
                            <TableCell>Word Tgt</TableCell>

                            <TableCell>
                                <Sortable property="search" label="SV" />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    label="EV"
                                    property="ev"
                                    tooltip="Estimated Visits Per Month (last 12 months)"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    label="CPC"
                                    property="cpc"
                                    tooltip="Average Cost Per Click"
                                />
                            </TableCell>
                            <TableCell>
                                <Sortable
                                    label="PPC"
                                    property="ppc"
                                    tooltip="Pay Per Click"
                                />
                            </TableCell>
                            <TableCell style={{ display: "none" }}>
                                4 - 6 Months
                            </TableCell>
                            <TableCell style={{ display: "none" }}>
                                7 - 9 Months
                            </TableCell>
                            <TableCell style={{ display: "none" }}>
                                10 - 12 Months
                            </TableCell>
                            <TableCell style={{ display: "none" }}>
                                12+ Months
                            </TableCell>
                            <TableCell>Date Update</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keywordsDataDB &&
                            keywordsDataDB.map((row, index) => {
                                let kwdata = null;
                                if (row.keyWordsResult) {
                                    try {
                                        kwdata = JSON.parse(row.keyWordsResult);
                                    } catch (e) {
                                        return;
                                    }
                                } else {
                                    return;
                                }
                                if (!kwdata.ev) {
                                    kwdata.ev = 0;
                                }
                                if (!kwdata.cpc) {
                                    kwdata.cpc = 0;
                                }

                                switch (kwdata?.level) {
                                    case "low":
                                        kwdata.color = "#008000"; // Green
                                        break;
                                    case "medium":
                                        kwdata.color = "#90EE90"; // Light green
                                        break;
                                    case "high":
                                        kwdata.color = "#FFFF00"; // Yellow
                                        break;
                                    case "others":
                                        kwdata.color = "#FF0000"; // Red
                                    default:
                                        break;
                                }
                                if (kwdata && kwdata.path == "-") {
                                    kwdata.path = "";
                                }
                                return (
                                    <TableRow
                                        key={index + 1}
                                        id={row._id}
                                        style={
                                            !keyArr.includes(row._id)
                                                ? null
                                                : { display: "none" }
                                        }
                                    >
                                        <TableCell className={classes.fixedNo}>
                                            {index + 1 || "-"}
                                        </TableCell>
                                        <TableCell className={classes.fixedKw}>
                                            {" "}
                                            <Button
                                                fullWidth
                                                size="small"
                                                style={{
                                                    backgroundColor:
                                                        kwdata?.color,
                                                }}
                                                color="primary"
                                                variant="outlined"
                                                onClick={() =>
                                                    CmSetActiveKeyword(
                                                        kwdata.url,
                                                        ["Singapore"],
                                                        {
                                                            mangools: 2702,
                                                            sem: "sg",
                                                        },
                                                        [kwdata.keywords],
                                                        kwdata
                                                    )
                                                }
                                                className="button-kw"
                                            >
                                                {row.keyWords}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            {kwdata.rank || "-"}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            <a
                                                aria-owns={
                                                    open
                                                        ? "mouse-over-popover"
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                onMouseEnter={(e) =>
                                                    handlePopoverOpen(
                                                        e,
                                                        paramsUrl + kwdata.path
                                                    )
                                                }
                                                onMouseLeave={
                                                    handlePopoverClose
                                                }
                                                href={
                                                    paramsUrl + kwdata.path ||
                                                    ""
                                                }
                                                target="_blank"
                                            >
                                                {" "}
                                                <div className={classes.flex}>
                                                    {/* <LinkIcon /> */}{" "}
                                                    {paramsUrl + kwdata.path ||
                                                        ""}
                                                </div>{" "}
                                            </a>
                                        </TableCell>

                                        <TableCell>
                                            {row?.keywordDensity?.kwInTitle
                                                ? "✔"
                                                : ""}
                                        </TableCell>
                                        <TableCell>
                                            {row?.keywordDensity?.kwInUrl
                                                ? "✔"
                                                : ""}
                                        </TableCell>
                                        <TableCell>
                                            {row?.keywordDensity
                                                ?.kwInDescription
                                                ? "✔"
                                                : ""}
                                        </TableCell>

                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.minDensity || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.maxDensity || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.averageDensity || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.keywordDensity
                                                ?.countKeyWord || "-"}
                                        </TableCell>

                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.minWordCount || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.maxWordCount || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.averageToptenWebsite
                                                ?.averageWordCount || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {row?.keywordDensity?.wordCount ||
                                                "-"}
                                        </TableCell>
                                        <TableCell>
                                            {kwdata.search || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {parseFloat(kwdata.ev).toFixed(0) ||
                                                "-"}
                                        </TableCell>
                                        <TableCell>
                                            {parseFloat(kwdata.cpc).toFixed(
                                                2
                                            ) || "-"}
                                        </TableCell>
                                        <TableCell>
                                            {kwdata.ppc || "-"}
                                        </TableCell>
                                        <TableCell style={{ display: "none" }}>
                                            {kwdata.level === "low" ? "✔" : ""}
                                        </TableCell>
                                        <TableCell style={{ display: "none" }}>
                                            {kwdata.level === "medium"
                                                ? "✔"
                                                : ""}
                                        </TableCell>
                                        <TableCell style={{ display: "none" }}>
                                            {kwdata.level === "high" ? "✔" : ""}
                                        </TableCell>
                                        <TableCell style={{ display: "none" }}>
                                            {kwdata.level === "others"
                                                ? "✔"
                                                : ""}
                                        </TableCell>
                                        <TableCell>
                                            {Moment(row.resultDate).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="launch"
                                                onClick={() =>
                                                    setUrl(
                                                        paramsUrl + kwdata.path
                                                    )
                                                }
                                            >
                                                <Launch fontSize="small" />
                                            </IconButton>
                                        </TableCell>

                                        <TableCell style={{ maxWidth: "40px" }}>
                                            <IconButton
                                                aria-label="refresh"
                                                onClick={() =>
                                                    refreshKeywordDb({
                                                        kw: kwdata.kw,
                                                        _id: row._id,
                                                        locationId:
                                                            row.locationId,
                                                        url: row.url,
                                                    })
                                                }
                                            >
                                                {!isLoading ? (
                                                    <RefreshOutlined fontSize="small" />
                                                ) : key != row._id ? (
                                                    <RefreshOutlined fontSize="small" />
                                                ) : (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ maxWidth: "40px" }}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                    removeKeywordDb(row._id)
                                                }
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>

                <div className={classes.bottomBar}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="keyword"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        multiline
                                        type="text"
                                        placeholder="i.e. seo services singapore"
                                    />
                                )}
                            />

                            <FormHelperText>Add More Keywords </FormHelperText>
                            <Button
                                id="addmore-kw-button"
                                color="primary"
                                variant="contained"
                                className="export-button"
                                type="submit"
                            >
                                ADD MORE
                            </Button>
                        </FormControl>
                    </form>
                    <div style={{ textAlign: "right" }}>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                            table="main-table"
                            filename={`${fileName}`}
                            sheet="tablexls"
                            buttonText="Export"
                        />
                    </div>
                </div>

                {keyword && (
                    <div>
                        <p>
                            <b>Disclaimer:</b>
                            <br />
                            Categorisation of related keywords and main sheet
                            for competition levels vary because app further
                            computes localised aspects of the keyword in
                            relation to the target URL.
                        </p>
                        <KeywordDomainDisplay />
                        <KeywordPageOneDisplay />
                        <div style={{ display: "flex" }}>
                            <RelatedKeywordTable type="Low" />
                            <RelatedKeywordTable type="Medium" />
                            <RelatedKeywordTable type="Hard" />
                        </div>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 65px)",
                }}
            >
                <CircularProgress />
            </div>
        );
    }
};

export default ViewKeywordUrl;
