import API from "./API";

/**
 * Collect relevant page info, related keyword and page info for a given url, keyword and location
 * @param {string} url
 * @param {string} keyword
 * @param {number} location
 * @returns
 */
export const getKeywordData = async (url, keyword, location) => {
    return API.mangoolsKeyworkService({
        url,
        locationId: location,
        keyWords: keyword,
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log(err);
        });

    // return {
    //   ...relevantPageInfo,
    //   ...relatedKeywords,
    //   ...pageInfo,
    //   kw: keyword,
    //   level,
    // };
};
