const base_uri = process.env.REACT_APP_CUSTOMAPI;
const pagespeed_uri = `${base_uri}/pagespeed-insights`;

export const getPageSpeedInsights = async (url, device = "mobile") => {
  const encUrl = encodeURIComponent(url);
  const reqOption = {
    headers: {
      "token": sessionStorage.getItem("token"),
      "logintype" :sessionStorage.getItem("login_type") ? sessionStorage.getItem("login_type") : "login",
    }
  }
  const req_url = `${pagespeed_uri}?url=${encUrl}&device=${device}`;
  try {
    const response = await fetch(req_url,reqOption);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
