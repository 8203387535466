import {
    Button,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@material-ui/core";

import React, { useState } from "react";
const TableNote = () => {
    return (
        <Table
            style={{
                marginTop: "40px",
                marginBottom: "40px",
                textAlign: "left",
            }}
        >
            <TableBody>
                <TableRow
                    style={{
                        marginTop: "20px",
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    <TableCell
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#008000",
                            marginRight: "20px",
                        }}
                    ></TableCell>
                    <TableCell
                        style={{
                            border: "none",
                            paddingLeft: "20px !important",
                        }}
                    >

                        <div style={{
                            paddingLeft: "20px ",
                        }} >  4 - 6 Months </div>
                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        marginTop: "20px",
                        textAlign: "left",
                        display: "block",
                    }}
                >
                    <TableCell
                        style={{
                            width: "20px",
                            height: "20px",

                            backgroundColor: "#90EE90",
                            marginRight: "20px",
                        }}
                    ></TableCell>
                    <TableCell
                        style={{
                            border: "none",
                            paddingLeft: "20px !important",
                        }}
                    > <div style={{
                        paddingLeft: "20px ",
                    }} >7 - 9 Months{" "} </div>

                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        marginTop: "20px",
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    <TableCell
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#FFFF00",
                            marginRight: "20px",
                        }}
                    ></TableCell>
                    <TableCell
                        style={{
                            border: "none",
                            paddingLeft: "20px !important",
                        }}
                    >

                        <div style={{
                            paddingLeft: "20px ",
                        }} >10 - 12 Months{" "} </div>
                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        marginTop: "20px",
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    <TableCell
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#FF0000",
                            marginRight: "20px",
                        }}
                    ></TableCell>
                    <TableCell
                        style={{
                            border: "none",
                            paddingLeft: "20px !important",
                        }}
                    >
                        <div style={{
                            paddingLeft: "20px ",
                        }} >12+ Months{" "} </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default TableNote;
