import axios from 'axios'
const appEnv = process.env


const apiClient = axios.create({
    baseURL: appEnv.REACT_APP_CUSTOMAPI,
    headers: { 
        crossDomain: true,
        'Access-Control-Allow-Origin': '*',
        "mode": 'cors',
        'Content-Type': 'application/x-www-form-urlencoded'

    },
});    

apiClient.interceptors.request.use((request) => {
    const accessToken = sessionStorage.getItem("token")
    const login_type = sessionStorage.getItem("login_type");
    if (accessToken) {
        request.headers.token = accessToken
        request.headers.loginType = login_type ? login_type : "login";
    }
    return request
})

// apiClient.interceptors.response.use(undefined, (error) => {
//     // Errors handling
//     const { response } = error
//     const { data, status } = response

//     if (data) {
//         if (status !== 401 && status !== 404) {
//             if (data.status === 422) {
//                console.log("aaaaaaaaa");
//             } else {
//                 console.log("bbbbbbbbbbbbb");
//             }
//         }
//     }
//     return data
// })

export default apiClient
