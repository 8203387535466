import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    TablePagination,
    TableFooter,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useKeywords } from "../../hooks/useKeywords";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "aliceblue",
        marginBottom: "20px",
        border: "1px solid cadetblue",
        padding: "0 10px",
    },
    textLeft: {
        textAlign: "left !important",
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
}
const KeywordPageOneDisplay = () => {
    const classes = useStyles();
    const { keyword, setActiveKeyword } = useKeywords();
    const [page, setPage] = React.useState(0);
    const rows = keyword.items;

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className={classes.root}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <h2>Keyword: {keyword.kw} </h2>
                <span
                    onClick={() => setActiveKeyword(null)}
                    style={{ color: "maroon", cursor: "pointer" }}
                >
                    X
                </span>
            </div>
            <TableContainer>
                <Table className="table-meta">
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>DA</TableCell>
                            <TableCell>PA</TableCell>
                            <TableCell>TF</TableCell>
                            <TableCell>CF</TableCell>
                            <TableCell>Links</TableCell>
                            <TableCell>FB</TableCell>
                            <TableCell>LPS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? keyword.items.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : keyword.items
                        ).map((item, index) => (
                            <TableRow TableRow key={index} hover={true}>
                                <TableCell>{index + 1 + page * 10}</TableCell>
                                <TableCell className={classes.textLeft}>
                                    <a
                                        href={item.url}
                                        rel="nofollow external noopener noreferrer"
                                        target="_blank"
                                    >
                                        {item.url}
                                    </a>
                                </TableCell>
                                <TableCell>{item.meta.pda || 0}</TableCell>
                                <TableCell>{item.meta.upa || 0}</TableCell>
                                <TableCell>{item.meta.tf || 0}</TableCell>
                                <TableCell>{item.meta.cf || 0}</TableCell>
                                <TableCell>{item.meta.links || 0}</TableCell>
                                <TableCell>{item.meta.fb || 0}</TableCell>
                                <TableCell>{item.meta.lps || 0}</TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        {/*  {(keyword.items || []).map((item, index) => (

              <TableRow key={index} hover={true}>
                <TableCell>{index + 1}</TableCell>
                <TableCell style={{ width: "60%", textAlign: "left " }} className={classes.textLeft}>
                  <a
                    href={item.url}
                    rel="nofollow external noopener noreferrer"
                    target="_blank"
                  >
                    {item.url}
                  </a>
                </TableCell>
                <TableCell>{item.meta.pda || 0}</TableCell>
                <TableCell>{item.meta.upa || 0}</TableCell>
                <TableCell>{item.meta.tf || 0}</TableCell>
                <TableCell>{item.meta.cf || 0}</TableCell>
                <TableCell>{item.meta.links || 0}</TableCell>
                <TableCell>{item.meta.fb || 0}</TableCell>
                <TableCell>{item.meta.lps || 0}</TableCell>
              </TableRow>
            ))} */}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    15,
                                    20,
                                    { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                className="list-url-nav"
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default KeywordPageOneDisplay;
