import React, { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useKeywords } from "../hooks/useKeywords";
import { Button, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { ClipLoader } from "react-spinners";


const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    marginTop: "2rem",
    marginBottom: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  creditBalance: {
    fontWeight: "bold",
  },
  statusContainer: { display: "flex", alignItems: "center" },
  statusText: { marginLeft: "8px", fontSize: "14px" },
  accordion: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
 

const HomePage = () => {
  const { user } = useAuth();
  const {
    status,
    credits,
    loadingCredits,
    getCreditBalance
  } = useKeywords();
  const classes = useStyles();

  useEffect(() => {
    getCreditBalance();
  }, []);

  return (
    <>
      <h1>WELCOME {String(user).toUpperCase()}</h1>
      <div>
            <p>
              Credit Balance:{" "}
              {loadingCredits ? (
                <span className={classes.creditBalance}>Loading...</span>
              ) : (
                <> 
                  <span className={classes.creditBalance}>
                    {_.get(credits, "serp.remaining", 0)}
                    {" / "}
                    {_.get(credits, "serp.total", 0)}
                  </span>
                </>
              )}
            </p>
            <p>
              Time to Reset:{" "}
              {loadingCredits ? (
                <span className={classes.creditBalance}>Loading...</span>
              ) : (
                <span className={classes.creditBalance}>
                  {_.get(credits, "ttr", "Fully charged")}
                </span>
              )}
            </p>
            {!!status && (
              <div className={classes.statusContainer}>
                <ClipLoader size={10} color={"#123abc"} />
                <p className={classes.statusText}>{status}</p>
              </div>
            )}
          </div>
    </>
  );
};

export default HomePage;
