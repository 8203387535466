import _ from "lodash";
import {
    Button,
    FormControl,
    FormHelperText,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useKeywords } from "../../hooks/useKeywords";
import ResultsTableSection from "./ResultsTableSection";
import { Controller, useForm } from "react-hook-form";
import SummaryCards from "./SummaryCards";
// import { TableExport } from "tableexport";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import TableNote from "../TableNote";

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        display: "flex",
        marginTop: theme.spacing(4),
        alignItems: "center",
        justifyContent: "space-between",
    },
    fixedNo: {
        position: "-webkit-sticky",
        position: "sticky",
        background: "whitesmoke",
        left: 0,
        zIndex: 1,
        width: "50px",
    },
    fixedKw: {
        position: "-webkit-sticky",
        position: "sticky",
        background: "whitesmoke",
        left: "50px",
        zIndex: 1,
    },
    tableKW: {
        marginBottom: 40,
        marginTop: 40,
        display: "block",
        overflowX: "auto",
        overflowY: "auto",
    },
}));

const ResultsTable = () => {
    const classes = useStyles();
    const { control, handleSubmit, setValue } = useForm();
    const {
        keywordsData,
        addKeyword,
        addMoreKeyword,
        byCategory,
        setActiveKeyword,
        setKeywordsData,
        keywords,
        url,
    } = useKeywords();
    /**
     * Sort rows by attribute
     */
    const [dataTable, setDataTable] = useState();
    const [fileName, setFileName] = useState("main-table");
    useEffect(() => {
        setActiveKeyword(null);

        if (Object.keys(keywordsData).length) {
            // const file = keywordsData[Object.keys(keywordsData)[0]].domain;
            // console.log(file);
            // const arrDomain = url.split(".");
            // console.log("filename",);
            let domain = new URL(url);
            const arrDomain = domain.hostname.replace("www.", "").split(".");
            setFileName(arrDomain[0]);
            // setFileName(arrDomain[0].replace(/^https?\:\/\//i, ""));
        }
        const priorities = {
            low: 0,
            medium: 1,
            high: 2,
            others: 3,
        };
        const data = Object.entries(keywordsData).sort(function (a, b) {
            // console.log("run sort",keywordsData);
            return priorities[a[1].level] - priorities[b[1].level];
        });
        Object.entries(data).forEach(([key, value], index) => {
            value["No"] = index + 1;
            setDataTable((k) => ({ ...k, [key]: value }));
            if (index === 0) {
                setActiveKeyword(key);
            }
        });
        setDataTable(Object.fromEntries(data));
        // console.log("useEffect", data);
    }, [keywordsData, keywords]);

    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const Sortable = ({ property, label, tooltip = null }) =>
        tooltip ? (
            <Tooltip title={tooltip}>
                <TableSortLabel
                    active={orderBy === property}
                    onClick={() => handleRequestSort(property)}
                    direction={orderBy === property ? order : "asc"}
                >
                    {label}
                </TableSortLabel>
            </Tooltip>
        ) : (
            <TableSortLabel
                active={orderBy === property}
                onClick={() => handleRequestSort(property)}
                direction={orderBy === property ? order : "asc"}
            >
                {label}
            </TableSortLabel>
        );

    /**
     * Categorize keywords
     */

    const filterByCategory = (data, category) => {
        return Object.entries(data)
            .filter((kwdata) => kwdata[1].level === category)
            .reduce((obj, curr) => {
                obj[curr[0]] = curr[1];
                return obj;
            }, {});
    };

    const onSubmit = async ({ keyword }) => {
        setValue("keyword", "");
        const myArrKeyword =
            keyword
                .toLowerCase()
                .replace(/\n/g, ",")
                .split(",")
                .filter((kw) => !!kw.trim()) || [];
        const kwTrim = myArrKeyword.map((kw, index) => {
            return kw.trim();
        });
        addMoreKeyword(kwTrim);
    };

    return (
        <>
            <TableNote />
            <Table
                size="small"
                id="main-table"
                aria-label="keywords results table"
                className={classes.tableKW}
            >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.fixedNo}>No.</TableCell>
                        <TableCell className={classes.fixedKw}>
                            <Sortable property="levelSort" label="Keyword" />
                        </TableCell>
                        <TableCell>
                            <Sortable property="rank" label="#" />
                        </TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>Meta Descr</TableCell>
                        <TableCell>
                            <Sortable property="minDensity" label="KW Min" />
                        </TableCell>
                        <TableCell>
                            <Sortable property="maxDensity" label="KW Max" />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                property="averageDensity"
                                label="KW Avg"
                            />
                        </TableCell>
                        <TableCell>
                            <Sortable property="countKeyWord" label="KW Tgt" />
                        </TableCell>

                        <TableCell>
                            <Sortable
                                property="minWordCount"
                                label="Word Min"
                            />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                property="maxWordCount"
                                label="Word Max"
                            />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                property="averageWordCount"
                                label="Word Avg"
                            />
                        </TableCell>
                        <TableCell>Word Tgt</TableCell>

                        <TableCell>
                            <Sortable property="search" label="SV" />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                label="EV"
                                property="ev"
                                tooltip="Estimated Visits Per Month (last 12 months)"
                            />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                label="CPC"
                                property="cpc"
                                tooltip="Average Cost Per Click"
                            />
                        </TableCell>
                        <TableCell>
                            <Sortable
                                label="PPC"
                                property="ppc"
                                tooltip="Pay Per Click"
                            />
                        </TableCell>
                        <TableCell style={{ display: "none" }}>
                            4 - 6 Months
                        </TableCell>
                        <TableCell style={{ display: "none" }}>
                            7 - 9 Months
                        </TableCell>
                        <TableCell style={{ display: "none" }}>
                            10 - 12 Months
                        </TableCell>
                        <TableCell style={{ display: "none" }}>
                            12+ Months
                        </TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!byCategory ? (
                        <ResultsTableSection
                            data={dataTable}
                            orderBy={orderBy}
                            order={order}
                        />
                    ) : (
                        <>
                            <ResultsTableSection
                                order={order}
                                orderBy={orderBy}
                                subheader="Low"
                                data={filterByCategory(keywordsData, "low")}
                            />
                            <ResultsTableSection
                                order={order}
                                orderBy={orderBy}
                                subheader="Medium"
                                data={filterByCategory(keywordsData, "medium")}
                            />
                            <ResultsTableSection
                                order={order}
                                orderBy={orderBy}
                                subheader="High"
                                data={filterByCategory(keywordsData, "high")}
                            />
                            <ResultsTableSection
                                order={order}
                                orderBy={orderBy}
                                subheader="Others"
                                data={filterByCategory(keywordsData, "others")}
                            />
                        </>
                    )}
                </TableBody>
            </Table>
            <div className={classes.bottomBar}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="keyword"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    multiline
                                    type="text"
                                    placeholder="i.e. seo services singapore"
                                />
                            )}
                        />

                        <FormHelperText>Add More Keywords </FormHelperText>
                        <Button
                            id="addmore-kw-button"
                            color="primary"
                            variant="contained"
                            className="export-button"
                            type="submit"
                        >
                            ADD MORE
                        </Button>
                    </FormControl>
                </form>
                <SummaryCards
                    low={filterByCategory(keywordsData, "low")}
                    medium={filterByCategory(keywordsData, "medium")}
                    high={filterByCategory(keywordsData, "high")}
                    keywordsData={keywordsData}
                />
                {/* <Button
          id="export-button"
          color="primary"
          variant="contained"
          className="export-button"
          onClick={() => {
            // TableExport(document.getElementById("main-table"));
            new TableExport(document.getElementById("main-table"));

            document.getElementById("export-button").classList.add("exported");
          }}
        >
          Export
        </Button> */}
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                    table="main-table"
                    filename={`${fileName}`}
                    sheet="tablexls"
                    buttonText="Export"
                />
            </div>
        </>
    );
};

export default ResultsTable;
