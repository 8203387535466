import React from "react";
import ResultsTableRow from "./ResultsTableRow";
import ResultsTableSubheaderRow from "./ResultsTableSubheaderRow";

const sorter = require('array-sorter');

const ResultsTableSection = ({
    data = {},
    order = null,
    orderBy = null,
    subheader = null,
}) => {
    const stableSort = (data) => {
        let entries = Object.entries(data);
        if (!order || !orderBy) return entries;

        return entries.sort((a, b) => {
            let aa = parseFloat(String(a[1][orderBy]).replace(/\D/g)) || 0;
            let bb = parseFloat(String(b[1][orderBy]).replace(/\D/g)) || 0;
            let dir = order === "desc" ? 1 : -1;
            if (bb < aa) return -1 * dir;
            if (bb > aa) return 1 * dir;
            return 0;
        });
    };

    if (Object.keys(data).length === 0) return null;

    const dataResult = []
    Object.keys(data).map((key, index) => {
        dataResult.push(data[key])
    });

    // if (order === 'asc') {
    //     sorter(dataResult)
    //         .orderBy(x => x.levelSort)
    //         .thenBy(x => x[orderBy])
    //         .sort();
    // } else {
    //     sorter(dataResult)
    //         .orderBy(x => x.levelSort)
    //         .thenByDescending(x => x[orderBy])
    //         .sort();
    // }

    if (order === 'asc') {
        sorter(dataResult)
            .orderBy(x => x[orderBy])
            .sort();
    } else {
        sorter(dataResult)
            .orderByDescending(x => x[orderBy])
            .sort();
    }

    return (
        <>
            {subheader && <ResultsTableSubheaderRow title={subheader} data={data} />}
            {dataResult.map((kwdata, index) => (
                <ResultsTableRow key={kwdata.kw} kwdata={kwdata} index={index + 1} />
            ))}
        </>
    );
};

export default ResultsTableSection;
