import _ from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import countries from "../catalogs/countries";
import { getCredits } from "../services/custom";
import { getKeywordData } from "../services/mangools";
import API from "../services/API";

const KeywordsContext = createContext();
export const useKeywords = () => useContext(KeywordsContext);

const KeywordsProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    const [url, setUrl] = useState(null);
    const [location, setLocation] = useState(null);
    const [locationData, setLocationData] = useState(null);
    const [keywords, setKeywords] = useState(null);
    const [keyword, setKeyword] = useState(null);

    const [status, setStatus] = useState("");
    const [keywordsData, setKeywordsData] = useState({});

    useEffect(() => {
        const keywordsSort = Array.from(new Set(keywords));
        setInitialized(!!url && !!location && !!locationData && !!keywordsSort);
        // setKeywordsData({});
        let arrFilter = [];
        const oldKw = Object.keys(keywordsData);
        if (keywordsData && keywords) {
            arrFilter = keywords.filter((item) => !oldKw.includes(item));
        } else {
            arrFilter = keywords;
        }

        if (url && location && locationData && arrFilter) {
            const fetchKeywords = async () => {
                for (let kw of arrFilter) {
                    setStatus(`Requesting data for ${arrFilter}`);
                    const param = {
                        url: url,
                        locationId: JSON.stringify(
                            locationData ? locationData.mangools : ""
                        ),
                        keyWords: kw,
                    };
                    const resApiData = await API.getMangoolsKeywork(param);

                    if (
                        resApiData &&
                        resApiData["0"] &&
                        resApiData["0"].keyWordsResult != "undefined"
                    ) {
                        await Promise.all(
                            resApiData.map(async (item) => {
                                const keyWordsResult = JSON.parse(
                                    item.keyWordsResult
                                );
                                let domain = new URL(url);
                                const paramsUrl =
                                    domain.protocol + "//" + domain.hostname;
                                const path =
                                    keyWordsResult && keyWordsResult.path != "-"
                                        ? keyWordsResult.path
                                        : "";
                                let urlWordCount = paramsUrl + path;
                                keyWordsResult["rank"] = parseInt(
                                    keyWordsResult?.rank
                                );
                                const param = {
                                    url: urlWordCount,
                                    keyword: item?.keyWords,
                                };
                                await API.getKeywordDensity(param).then(
                                    (response) => {
                                        item["keywordDensity"] = response;
                                    }
                                );

                                let stringUrl = "";
                                JSON.parse(item.keyWordsResult)
                                    .items.slice(0, 9)
                                    .forEach((element) => {
                                        stringUrl += element.url + ",";
                                    });
                                await API.getAverageToptenWebsite({
                                    url: stringUrl,
                                    keyword: item?.keyWords,
                                })
                                    .then((response) => {
                                        item["averageToptenWebsite"] = response;
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            })
                        );
                    }

                    const resultDate =
                        resApiData && resApiData[0]
                            ? resApiData[0].resultDate
                            : "null";
                    const lastTwoWeek = new Date() - 12096e5;
                    const dateUpdateData = new Date(resultDate).getTime();

                    if (
                        resApiData &&
                        dateUpdateData > lastTwoWeek &&
                        resApiData["0"] &&
                        resApiData["0"].keyWordsResult != "undefined"
                    ) {
                        const sortBy = [];
                        const kwdata = JSON.parse(resApiData[0].keyWordsResult);
                        kwdata["rank"] = parseInt(kwdata?.rank);

                        switch (kwdata?.level) {
                            case "low":
                                sortBy.push({ levelSort: 1 });
                                break;
                            case "medium":
                                sortBy.push({ levelSort: 2 });
                                break;
                            case "high":
                                sortBy.push({ levelSort: 3 });
                                break;
                            case "others":
                                sortBy.push({ levelSort: 4 });
                            default:
                                break;
                        }
                        setKeywordsData((k) => ({
                            ...k,
                            [kw]: {
                                ...kwdata,
                                ...resApiData[0].keywordDensity,
                                ...resApiData[0].averageToptenWebsite,
                                ...sortBy[0],
                            },
                        }));
                        setStatus("");

                        getCreditBalance();
                    } else {
                        await refreshKeyword(kw);
                    }
                }
            };
            fetchKeywords();
        }
    }, [url, location, locationData, keywords]);

    const reset = async () => {
        await setInitialized(false);
        await setLocationData(null);
        await setKeywordsData({});
        await setLocation(null);
        await setKeywords(null);
        await setUrl(null);
    };

    const submitForm = ({ url, location, keywords }) => {
        setUrl(url[url.length - 1] !== "/" ? url + "/" : url);
        setLocation(location);
        setLocationData(countries[location]);
        setKeywords([
            ...new Set(
                keywords
                    .replace(/\n/g, ",")
                    .split(",")
                    .map((kw) => String(kw).toLowerCase().trim())
                    .filter((kw) => !!kw) || []
            ),
        ]);
    };

    const addKeyword = (kw) => {
        const parsedKw = String(kw).toLowerCase().trim();
        if ((keywords || []).indexOf(parsedKw) === -1) {
            setKeywords((kwds) => [...kwds, parsedKw]);
        }
    };
    const addMoreKeyword = (kw) => {
        setKeywords((kwds) => [...kwds, ...kw]);
    };

    const removeKeyword = async (kw) => {
        const parsedKw = String(kw).toLowerCase().trim();
        const kwIndex = (keywords || []).indexOf(parsedKw);
        if (kwIndex > -1) {
            await delete keywordsData[kw];
            await setKeywordsData((keywordsDataTemp) => keywordsDataTemp);
            await setKeywords((kwds) => kwds.filter((kwd) => kwd !== kw));

            // console.log(keywordsData);
            // await setKeywordsData(keywordsData);
        }
    };

    const refreshKeyword = async (kw) => {
        const key = `${url}-${kw}`;
        setStatus(`Requesting data for ${kw}`);
        const paramGet = {
            url: url,
            locationId: JSON.stringify(
                locationData ? locationData.mangools : ""
            ),
            keyWords: kw,
        };
        const resApiData = await API.getMangoolsKeywork(paramGet);
        if (resApiData && resApiData[0] && resApiData[0]._id && locationData) {
            await getKeywordData(url, kw, locationData.mangools)
                .then(async (kwdata) => {
                    setStatus(`Storing data for ${kw}`);
                    let domain = new URL(url);
                    const paramsUrl = domain.protocol + "//" + domain.hostname;
                    const path =
                        kwdata && kwdata.path != "-" ? kwdata.path : "";

                    let urlWordCount = paramsUrl + path;
                    kwdata["rank"] = parseInt(kwdata?.rank);

                    // console.log(response);
                    const paramGetKeywordDensity = {
                        url: urlWordCount,
                        keyword: kwdata.kw,
                    };
                    await API.getKeywordDensity(paramGetKeywordDensity).then(
                        (res1) => {
                            // kwdata["keywordDensity"] = res1;
                            delete res1.url;
                            kwdata = { ...kwdata, ...res1 };
                        }
                    );

                    let stringUrl = "";

                    kwdata.items.slice(0, 9).forEach((element) => {
                        stringUrl += element.url + ",";
                    });
                    await API.getAverageToptenWebsite({
                        url: stringUrl,
                        keyword: kwdata?.kw,
                    })
                        .then((res2) => {
                            // kwdata["averageToptenWebsite"] = res2;
                            delete res2.url;
                            kwdata = { ...kwdata, ...res2 };
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    const sortBy = [];
                    switch (kwdata?.level) {
                        case "low":
                            sortBy.push({ levelSort: 1 });
                            break;
                        case "medium":
                            sortBy.push({ levelSort: 2 });
                            break;
                        case "high":
                            sortBy.push({ levelSort: 3 });
                            break;
                        case "others":
                            sortBy.push({ levelSort: 4 });
                        default:
                            break;
                    }

                    const param = new URLSearchParams({
                        _id: resApiData[0]._id,
                        keyWordsResult: JSON.stringify(kwdata),
                    });
                    API.updateMangoolsKeywork(param)
                        .then((response) => {})
                        .catch((e) => {
                            console.log(e);
                        });
                    setKeywordsData((k) => ({ ...k, [kw]: kwdata }));
                    getCreditBalance();
                    setStatus("");
                })
                .catch((err) => {
                    setStatus(`An error ocurred for ${kw}`);
                    getCreditBalance();
                    setStatus("");
                });
        } else if (locationData) {
            await getKeywordData(url, kw, locationData.mangools)
                .then((kwdata) => {
                    if (kwdata.message) {
                        NotificationManager.warning(
                            kwdata.errors ? kwdata.errors[0] : "Error",
                            kwdata.message
                                ? kwdata.message
                                : "Please check your credit or keyword length",
                            5000
                        );
                        removeKeyword(kw);
                        setStatus("");
                        return;
                    } else {
                        setStatus(`Storing data for ${kw}`);
                        let data = kwdata;
                        const param = new URLSearchParams({
                            url: url,
                            locationId: JSON.stringify(locationData.mangools),
                            keyWords: kw,
                            keyWordsResult: JSON.stringify(data),
                        });
                        if (kwdata) {
                            API.createMangoolsKeywork(param)
                                .then(async (response) => {
                                    let domain = new URL(url);
                                    const paramsUrl =
                                        domain.protocol +
                                        "//" +
                                        domain.hostname;
                                    const keyWordsResult = JSON.parse(
                                        response.keyWordsResult
                                    );
                                    const path =
                                        keyWordsResult &&
                                        keyWordsResult.path != "-"
                                            ? keyWordsResult.path
                                            : "";
                                    let urlWordCount = paramsUrl + path;

                                    const param = {
                                        url: urlWordCount,
                                        keyword: response?.keyWords,
                                    };
                                    await API.getKeywordDensity(param).then(
                                        (res1) => {
                                            response["keywordDensity"] = res1;
                                        }
                                    );

                                    let stringUrl = "";

                                    JSON.parse(response.keyWordsResult)
                                        .items.slice(0, 9)
                                        .forEach((element) => {
                                            stringUrl += element.url + ",";
                                        });
                                    await API.getAverageToptenWebsite({
                                        url: stringUrl,
                                        keyword: response?.keyWords,
                                    })
                                        .then((res2) => {
                                            response["averageToptenWebsite"] =
                                                res2;
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                    const sortBy = [];
                                    const kwdata = JSON.parse(
                                        response.keyWordsResult
                                    );
                                    kwdata["rank"] = parseInt(kwdata?.rank);

                                    switch (kwdata?.level) {
                                        case "low":
                                            sortBy.push({ levelSort: 1 });
                                            break;
                                        case "medium":
                                            sortBy.push({ levelSort: 2 });
                                            break;
                                        case "high":
                                            sortBy.push({ levelSort: 3 });
                                            break;
                                        case "others":
                                            sortBy.push({ levelSort: 4 });
                                        default:
                                            break;
                                    }
                                    setKeywordsData((k) => ({
                                        ...k,
                                        [kw]: {
                                            ...kwdata,
                                            ...response.keywordDensity,
                                            ...response.averageToptenWebsite,
                                            ...sortBy[0],
                                        },
                                    }));
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }
                        getCreditBalance();
                        setStatus("");
                    }
                })
                .catch((err) => {
                    setStatus(`An error ocurred for ${kw}`);
                    getCreditBalance();
                    setStatus("");
                });
        }
    };

    const setActiveKeyword = (kw) => {
        setKeyword(kw && keywordsData[kw] ? keywordsData[kw] : null);
    };
    const CmSetActiveKeyword = (
        url,
        location,
        locationData,
        keywords,
        keyword
    ) => {
        setKeyword(keyword);
        // setUrl(url);
        // setLocation(location);
        // setLocationData(locationData);
        setKeywords(keywords);
    };

    /**
     * Update credit balance
     */

    const [credits, setCredits] = useState({});
    const [loadingCredits, setLoadingCredits] = useState(true);
    const getCreditBalance = () => {
        setLoadingCredits(true);
        getCredits().then((res) => {
            if (res) {
                setCredits(res.credits);
                setLoadingCredits(false);
            }
        });
    };

    useEffect(() => {
        getCreditBalance();
        // eslint-disable-next-line
    }, []);

    /**
     * Toggle by category
     */

    const [byCategory, setByCategory] = useState(false);
    const toggleByCategory = () => {
        setByCategory((value) => !value);
    };

    return (
        <KeywordsContext.Provider
            value={{
                initialized,
                status,
                url,
                location,
                locationData,
                keywords,
                keywordsData,
                keyword,
                credits,
                loadingCredits,
                byCategory,
                reset,
                submitForm,
                addKeyword,
                addMoreKeyword,
                removeKeyword,
                refreshKeyword,
                setActiveKeyword,
                toggleByCategory,
                getCreditBalance,
                CmSetActiveKeyword,
                setKeywordsData,
                setUrl,
                setLocationData,
                setStatus,
            }}
        >
            {children}
        </KeywordsContext.Provider>
    );
};

export default KeywordsProvider;
