import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../services/API";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useGoogleLogout, useGoogleLogin } from "react-google-login";
const clientId = process.env.REACT_APP_GOOGLE_ID;

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(sessionStorage.getItem("user"));
    const [status, setStatus] = useState(null);

    const onLoginGoogleSuccess = (res) => {
        NotificationManager.warning(
            "Login Success: " + res.profileObj.name,
            "",
            3000
        );
        sessionStorage.setItem("token", res.tokenId);
        sessionStorage.setItem("login_type", "google");
        sessionStorage.setItem("user", res.profileObj.name);
        setUser(res.profileObj.name);
    };

    const onLoginGoogleFailure = (res) => {
        NotificationManager.warning("Login failed", "", 3000);
    };
    const onLogoutGoogleSuccess = (res) => {
        NotificationManager.warning(
            "Login Success: " + res.profileObj.name,
            "",
            3000
        );
    };

    const onLogoutGoogleFailure = (res) => {
        NotificationManager.warning("Login failed", "", 3000);
    };

    const { signIn } = useGoogleLogin({
        onSuccess: onLoginGoogleSuccess,
        onFailure: onLoginGoogleFailure,
        clientId,
        isSignedIn: true,
        accessType: "offline",
        hostedDomain:'mediaone.co',
    });

    const { signOut } = useGoogleLogout({
        clientId,
        onLogoutSuccess: onLogoutGoogleSuccess,
        onFailure: onLogoutGoogleFailure,
        hostedDomain:'mediaone.co',
    });

    const login = async (username, password) => {
        const param = new URLSearchParams({
            userName: username,
            password,
        });
        API.login(param)
            .then((response) => {
                if (response.status === 200 && response) {
                    sessionStorage.setItem("token", response.data.token);
                    sessionStorage.setItem("user", response.data.userName);
                    setUser(response.data.userName);
                    NotificationManager.success("Login success", "", 3000);
                }
            })
            .catch((e) => {
                setStatus("Invalid password");
                NotificationManager.warning(e.response.data.message, "", 3000);
                logout();
            });
    };

    const logout = async () => {
        sessionStorage.clear();
        signOut();
        await setUser(null);
    };

    return (
        <AuthContext.Provider
            value={{ user, status, login, logout, signIn, signOut }}
        >
            {children}
            <NotificationContainer />
        </AuthContext.Provider>
    );
};

export default AuthProvider;
