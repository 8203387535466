import React, { useState } from "react";
import { Button, IconButton, TableCell, TableRow, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useKeywords } from "../../hooks/useKeywords";
import { RefreshOutlined } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from '@material-ui/core/Popover';
import API from './../../services/API'
import { setUrl } from "../../hooks/useKeywords";
import { Launch } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
    bottomBar: {
        display: "flex",
        marginTop: theme.spacing(4),
        alignItems: "center",
        justifyContent: "space-between",
    },
    fixedNo: {
        position: '-webkit-sticky',
        position: 'sticky',
        background: 'whitesmoke',
        left: 0,
        zIndex: 1,
        width: "50px"
    },
    fixedKw: {
        position: '-webkit-sticky',
        position: 'sticky',
        background: 'whitesmoke',
        left: "50px",
        zIndex: 1,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const ResultsTableRow = ({ kwdata, index }) => {
    const { setActiveKeyword, removeKeyword, refreshKeyword, url, setUrl } = useKeywords();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [contentPopup, setContentPopup] = useState({ title: <CircularProgress />, description: <CircularProgress /> });
    if (!kwdata.ev) {
        kwdata.ev = 0;
    }
    if (!kwdata.cpc) {
        kwdata.cpc = 0;
    }

    switch (kwdata?.level) {
        case 'low':
            kwdata.color = '#008000' // Green
            break
        case 'medium':
            kwdata.color = '#90EE90' // Light green
            break
        case 'high':
            kwdata.color = '#FFFF00' // Yellow
            break
        case 'others':
            kwdata.color = '#FF0000' // Red
        default:
            break
    }

    const handlePopoverOpen = (event, url) => {

        const param = { url: url };
        API.getTitleAndDesc(param).then((response) => {
            // const content = "Title: " + response.title + "<hr> Description: " + response.description;
            setContentPopup(response)
        });
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setContentPopup({ title: <CircularProgress />, description: <CircularProgress /> })
    };

    const open = Boolean(anchorEl);
    if (kwdata && kwdata.path == "-") {
        kwdata.path = "";
    }
    let domain = (new URL(url));
    const paramsUrl = domain.protocol + "//" + domain.hostname;

    return (

        <TableRow>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <h4><b>Title: </b> {contentPopup.title}</h4>
                <p><b>Description: </b> {contentPopup.description}</p>
            </Popover>
            <TableCell className={classes.fixedNo} >{index || "-"}</TableCell>
            <TableCell className={classes.fixedKw}>
                <Button
                    fullWidth
                    size="small"
                    style={{ backgroundColor: kwdata?.color }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setActiveKeyword(kwdata.kw)}
                    className="button-kw"
                >
                    {kwdata.kw}
                </Button>
            </TableCell>
            <TableCell>{kwdata.rank || "-"}{kwdata.rank == 50 ? "+" : ""}</TableCell>
            <TableCell className="text-left"  >
                <a aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, paramsUrl + kwdata.path)}
                    onMouseLeave={handlePopoverClose} href={paramsUrl + kwdata.path || ""} target="_blank" > {paramsUrl + kwdata.path || ""}</a>
            </TableCell>

            <TableCell>{kwdata.kwInTitle ? "✔" : ""}</TableCell>
            <TableCell>{kwdata.kwInUrl ? "✔" : ""}</TableCell>
            <TableCell>{kwdata.kwInDescription ? "✔" : ""}</TableCell>
            <TableCell>{kwdata.minDensity || "-"}</TableCell>
            <TableCell>{kwdata.maxDensity || "-"}</TableCell>
            <TableCell>{kwdata.averageDensity || "-"}</TableCell>
            <TableCell>{kwdata.countKeyWord || "-"}</TableCell>
            <TableCell>{kwdata.minWordCount || "-"}</TableCell>
            <TableCell>{kwdata.maxWordCount || "-"}</TableCell>
            <TableCell>{kwdata.averageWordCount || "-"}</TableCell>
            <TableCell>{kwdata.wordCount || "-"}</TableCell>
            <TableCell>{kwdata.search || "-"}</TableCell>
            <TableCell>{parseFloat(kwdata.ev).toFixed(0) || "-"}</TableCell>
            <TableCell>{parseFloat(kwdata.cpc).toFixed(2) || "-"}</TableCell>
            <TableCell>{kwdata.ppc || "-"}</TableCell>
            <TableCell style={{ display: "none" }}>{kwdata.level === "low" ? "✔" : ""}</TableCell>
            <TableCell style={{ display: "none" }}>{kwdata.level === "medium" ? "✔" : ""}</TableCell>
            <TableCell style={{ display: "none" }}>{kwdata.level === "high" ? "✔" : ""}</TableCell>
            <TableCell style={{ display: "none" }}>{kwdata.level === "others" ? "✔" : ""}</TableCell>
            <TableCell>
                <IconButton
                    aria-label="delete"
                    onClick={() => setUrl(paramsUrl + kwdata.path)}
                >
                    <Launch fontSize="small" />
                </IconButton>
            </TableCell>

            <TableCell style={{ maxWidth: "40px" }}>
                <IconButton
                    aria-label="delete"
                    onClick={() => refreshKeyword(kwdata.kw)}
                >
                    <RefreshOutlined fontSize="small" />
                </IconButton>
            </TableCell>
            <TableCell style={{ maxWidth: "40px" }}>
                <IconButton
                    aria-label="delete"
                    onClick={() => removeKeyword(kwdata.kw)}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default ResultsTableRow;
